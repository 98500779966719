<template>
  <div class="bg-white p-4 shadow" :id="'editorjs' + holder"></div>
</template>

<script>
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import Undo from 'editorjs-undo';
import Warning from '@editorjs/warning';
import delimiter from '@editorjs/delimiter';
import Alert from 'editorjs-alert';
import Paragraph from 'editorjs-paragraph-with-alignment';
import Checklist from '@editorjs/checklist';
import NestedList from '@editorjs/nested-list';
import SimpleImage from '@editorjs/simple-image';
import Embed from '@editorjs/embed';
import InlineImage from 'editorjs-inline-image';
import Table from '@editorjs/table';
import AnyButton from 'editorjs-button';
import InlineCode from '@editorjs/inline-code';
import Marker from '@editorjs/marker';
import Underline from '@editorjs/underline';
import Hyperlink from 'editorjs-hyperlink';
import TextSpolier from 'editorjs-inline-spoiler-tool';
import ChangeCase from 'editorjs-change-case';
import ColorPlugin from 'editorjs-text-color-plugin';
import FontSize from 'editorjs-inline-font-size-tool';
import edjsHTML from 'editorjs-html';
//import LinkTool from '@editorjs/link';
//import AttachesTool from '@editorjs/attaches';
//import ImageGallery from '@rodrigoodhin/editorjs-image-gallery';


export default {
  name: "Markdown",
  props: {
    holder: {
      type: String,
      default: ""
    },
    data: {
      type: [String, Array, Object],
      default: ""
    }
  },
  data() {
    return {
      editor: {}
    }
  },
  methods: {
    saveMD: function () {
      this.editor.save().then((outputData) => {
        const edjsParser = edjsHTML()
        const html = edjsParser.parse(outputData)
        const res = JSON.stringify({
          json: outputData,
          html: html
        })
        this.$emit("changed", res)
      }).catch((error) => {
        console.log('Saving failed: ', error)
      })
    }
  },
  mounted() {
    this.editor = new EditorJS({
      holder: 'editorjs' + this.holder,
      autofocus: true,
      data: this.data,
      placeholder: "Let`s write an awesome story!",
      onChange: () => {
        this.saveMD()
      },
      onReady: () => {
        const editor = this.editor
        new Undo({ editor })
      },
      logLevel: 'ERROR',
      tools: {
        header: {
          class: Header,
          inlineToolbar: true,
          shortcut: "cmd+h",
          config: {
            levels: [2, 3, 4],
            defaultLevel: 3
          }
        },
        list: {
          class: NestedList,
          inlineToolbar: true,
          shortcut: "cmd+l"
        },
        warning: {
          class: Warning,
          inlineToolbar: true,
          shortcut: 'CMD+SHIFT+W',
          config: {
            titlePlaceholder: 'Title',
            messagePlaceholder: 'Message',
          },
        },
        delimiter: {
          class: delimiter,
          inlineToolbar: true,
          shortcut: 'CMD+D',
        },
        alert: {
          class: Alert,
          inlineToolbar: true,
          shortcut: 'CMD+SHIFT+A',
          config: {
            defaultType: 'primary',
            messagePlaceholder: 'Enter something',
          },
        },
        paragraph: {
          class: Paragraph,
          inlineToolbar: true,
        },
        checklist: {
          class: Checklist,
          inlineToolbar: true,
        },
        image: {
          class: SimpleImage,
          inlineToolbar: true,
        },
        image2: {
          class: InlineImage,
          inlineToolbar: true,
          config: {
            embed: {
              display: true,
            },
            unsplash: {
              appName: 'your_app_name',
              clientId: 'your_client_id'
            }
          }
        },
        embed: {
          class: Embed,
          inlineToolbar: true,
          config: {
            services: {
              youtube: true,
              facebook: true,
              instagram: true,
              twitter: true,
              'twitch-video': true,
              vimeo: true,
              gfycat: true,
              miro: true,
            }
          }
        },
        // todo: gallery hasn't readOnly from editorJS
        // imageGallery: ImageGallery,
        table: {
          class: Table,
          inlineToolbar: true,
          config: {
            rows: 2,
            cols: 3,
          },
        },
        AnyButton: {
          class: AnyButton,
          inlineToolbar: false,
          config:{
            css:{
              "btnColor": "btn--gray",
            }
          }
        },
        inlineCode: {
          class: InlineCode,
          shortcut: 'CMD+SHIFT+M',
        },
        Marker: Marker,
        underline: Underline,
        TextSpolier: TextSpolier,
        fontSize: FontSize,
        link: {
          class: Hyperlink,
          config: {
            shortcut: 'CMD+L',
            target: '_blank',
            rel: 'nofollow',
            availableTargets: ['_blank', '_self'],
            availableRels: ['author', 'noreferrer'],
            validate: false,
          }
        },
        changeCase: {
          class: ChangeCase,
          config: {
            showLocaleOption: true,
            locale: 'en'
          }
        },
        Color: {
          class: ColorPlugin,
          config: {
            customPicker: true,
            colorCollections: ['#EC7878','#9C27B0','#673AB7','#3F51B5','#0070FF','#03A9F4','#00BCD4','#4CAF50','#8BC34A','#CDDC39', '#FFF'],
            defaultColor: '#FF1300',
            type: 'text',
          }
        },
        //todo: image server side https://github.com/editor-js/image
        //todo: https://github.com/editor-js/attaches
        // attaches: {
        //   class: AttachesTool,
        //   config: {
        //     endpoint: 'http://localhost:8008/uploadFile'
        //   }
        // }
        //todo: https://github.com/editor-js/link
        // link: {
        //   class: LinkTool,
        //   inlineToolbar: true,
        // },


      },
    })
  }
}
</script>
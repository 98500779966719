<template>
  <router-link class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase
                  rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1
                  ease-linear transition-all duration-150
                 inline-flex select-none"
               :to="href" :class="size+' '+flex+' '+widttt">
    <slot></slot>
  </router-link>
</template>

<script>
export default {
  name: "ButtonRouter",
  props: {
    href: [String, Object],
    widttt: {
      type: String,
      default: "w-full"
    },
    size: {
      type: String,
      default: "px-4 py-3",
    },
    flex: {
      type: String,
      default: "items-center justify-center"
    }
  },
}
</script>

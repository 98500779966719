import store from '@/store'


async function nvgpost(endpoint, method, data, headers, funcSuccess, funcFail, needAuth) {
    let instHeaders = {
        "Content-Type": "application/json",
    }

    try {
        let responseObject = {
            method: method,
            headers: Object.assign({}, instHeaders, headers),
            cache: "no-cache",
            credentials: "include"
        }

        if(method !== "GET")
            responseObject.body = JSON.stringify(data)
        else
            endpoint += "?" + new URLSearchParams(data)

        let response = await fetch(endpoint, responseObject)
        let json = await response.json()

        if(json.authed === undefined && needAuth && store.getters.isAuthenticated)
        {
            if(funcFail !== undefined)
                funcFail(json)
            store.dispatch("logout")
            return false
        }

        if(funcSuccess !== undefined)
            funcSuccess(json)

        return json
    }
    catch (e) {
        throw Error(e)
    }
}

export default nvgpost
export function difference(array1, array2)  {
    let array_one = JSON.parse(JSON.stringify([...array1]))
    let array_two = JSON.parse(JSON.stringify([...array2]))
    array_one.forEach(function(v){ delete v.type; delete v.height })
    array_two.forEach(function(v){ delete v.type; delete v.height })

    return array_one.filter(object1 => {
        return !array_two.some(object2 => {
            return JSON.stringify(object1) === JSON.stringify(object2)
        })
    })
}

export function difference_deleted(array1)  {
    return array1.filter(object1 => {
        return object1.deleted !== undefined
    })
}
export function convertToCSV(objArray) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray
    let str = ''

    for (let i = 0; i < array.length; i++) {
        let line = ''
        for (let index in array[i]) {
            try {
                if (line != '') line += ','
                let a = array[i][index].toString()
                    .replaceAll(","," ").replaceAll(/(?:\r\n|\r|\n)/g, ' ')
                a = a.replaceAll("\n"," ").replaceAll("\\n"," ")
                line += a
            }
            catch (e) {
                //
            }
        }

        str += line + '\r\n'
    }

    return str
}

export function exportCSVFile(headers, csv, fileTitle) {
    if (headers) {
        csv = headers.join(",")+"\n"+csv
    }

    const exportedFilenmae = fileTitle + '.csv' || 'export.csv'

    let blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
    if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, exportedFilenmae)
    } else {
        let link = document.createElement("a")
        if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            let url = URL.createObjectURL(blob)
            link.setAttribute("href", url)
            link.setAttribute("download", exportedFilenmae)
            link.style.visibility = 'hidden'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        }
    }
}
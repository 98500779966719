<template>
  <div class="fixed top-8 left-0 z-60 w-full flex items-center justify-center transform transition"
       :class="toShow ? '' : '-translate-y-48'">
    <div class="bg-white border border-2 shadow-md text-lg px-5 py-6">
    <span :class="messageType===1 ? 'wb-text-green' : 'text-red-400'">
      {{ this.showMessage }}
    </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Alert",
  data() {
    return {
      toShow: false,
      showMessage: "",
      messageType: 1,
    }
  },
  methods: {
    alertDo: function (text, type) {
      this.toShow = true
      this.showMessage = text
      this.messageType = type
      setTimeout(function () {
        this.toShow = false
        this.showMessage = ""
        this.messageType = 1
      }.bind(this),5000)
    }
  },
  mounted() {
    this.$root.$on('alert', function (text, type) {
      this.alertDo(text, type)
    }.bind(this))
  }
}
</script>
import {TToggle, TDropdown} from "vue-tailwind/dist/components";


export const uiSettings = {
    TToggle: {
        component: TToggle,
        props: {
            classes: {
                wrapper: 'bg-gray-100 rounded-full border-2 border-transparent focus:ring-2 focus:ring-green-500 focus:outline-none focus:ring-opacity-50',
                wrapperChecked: 'wb-bg-green rounded-full',
                wrapperDisabled: 'bg-gray-100 rounded-full border-2 border-transparent focus:ring-2 focus:ring-green-500 focus:outline-none focus:ring-opacity-50',
                wrapperCheckedDisabled: 'wb-bg-green',
                button: 'h-5 w-5 rounded-full bg-white shadow flex items-center justify-center text-gray-400 text-xs',
                buttonChecked: 'h-5 w-5 rounded-full bg-white shadow flex items-center justify-center wb-text-green text-xs',
                checkedPlaceholder: 'rounded-full w-5 h-5 flex items-center justify-center text-gray-400 text-xs',
                uncheckedPlaceholder: 'rounded-full w-5 h-5 flex items-center justify-center text-gray-400 text-xs'
            },
        }
    },
    TDropdown: {
        component: TDropdown,
        props: {
            fixedClasses: {
                button: 'flex items-center text-white block px-4 py-2 transition duration-100 ease-in-out border border-transparent rounded shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed',
                wrapper: 'inline-flex flex-col',
                dropdownWrapper: 'relative z-10',
                dropdown: 'origin-top-left absolute left-0 w-56 rounded shadow mt-1',
                enterClass: 'opacity-0 scale-95',
                enterActiveClass: 'transition transform ease-out duration-100',
                enterToClass: 'opacity-100 scale-100',
                leaveClass: 'opacity-100 scale-100',
                leaveActiveClass: 'transition transform ease-in duration-75',
                leaveToClass: 'opacity-0 scale-95'
            },
            classes: {
                button: 'wb-bg-green hover:bg-green-600',
                dropdown: 'bg-white'
            },
            variants: {
                danger: {
                    button: 'bg-red-500 hover:bg-red-600',
                    dropdown: 'bg-red-50'
                }
            }
        }
    },

}
<template>
  <input class="border-0 placeholder-blueGray-300 rounded text-sm px-3 py-3
                  focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
         :class="[bg, shadow, text].join(' ')"
         :style="'width: ' + this.width + 'px;' + (bg === 'wb-bg-orange' ? ' background: #f6bba6;' :
         (bg === 'wb-bg-red' ? ' background: #F16063;' : ''))"
         v-on:input="upd($event)"
         v-on:keyup.enter="vkeyup()">
</template>

<script>
export default {
  name: "Input",
  props: {
    width: [Number, String],
    vkeyup: Function,
    bg: {
      type: String,
      default: "bg-white"
    },
    shadow: {
      type: String,
      default: "shadow"
    },
    text: {
      type: String,
      default: "text-blueGray-600"
    },
    onchangefunc: {
      type: Function,
      default: () => {

      }
    },
  },
  methods: {
    upd: function (e) {
      this.onchangefunc(e)
      this.$emit('input', e.target.value)
    }
  }
}
</script>

<template>
  <div>
    <div class="fixed top-0 left-0 w-screen wb-bg-black overflow-hidden z-60 transition"
         :class="{'opacity-0': !confirmToggled,'opacity-50': confirmToggled,'h-0': !confirmToggled,'h-screen': confirmToggled}"></div>
    <div class="fixed top-0 left-0 w-full grid lg:grid-cols-3 mt-10 z-60 transition transform"
         :class="{'opacity-0': !confirmToggled,'h-0': !confirmToggled,'-translate-y-96': !confirmToggled,'h-auto': confirmToggled}">
      <div class="lg:col-start-2 flex items-start justify-center flex-col gap-6 bg-white rounded-lg py-6 px-6">
        <h2 class="text-2xl">
          Are you sure?
        </h2>
        <p>
          Confirm <b class="wb-text-gray">"{{confirm.text}}"</b>
        </p>
        <div class="grid lg:grid-cols-2 w-full gap-8 mt-6">
          <Button @wasClicked="confirm.yesFunc()" colors="text-white wb-bg-green">Yes</Button>
          <Button @wasClicked="disableConfirm()" colors="text-white wb-bg-red">No</Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Inter/Button";


export default {
  name: "Confirm",
  components: {Button},
  computed: {
    confirm: function () {
      return this.$store.state.confirmWindow
    },
    confirmToggled: function () {
      return this.$store.state.confirmWindow.toggled
    },
  },
  methods: {
    disableConfirm: function () {
      this.$store.commit("toggleConfirm")
    }
  },
}
</script>
<template>
  <div>
    <router-view :key="randomId" />

    <div class="fixed bottom-2 left-2 z-30" style="font-size: 5px; color: #c0c0c0">
      v{{ version }}
    </div>

    <Alert />
    <Confirm />
  </div>
</template>

<script>
import Alert from "@/components/Block/Alert"
import Confirm from "@/components/Block/Confirm";


export default {
  name: 'App',
  components: {
    Confirm,
    Alert,
  },
  data() {
    return {
      version: "0.0.1",
    }
  },
  computed: {
    randomId () {
      return this.$route.fullPath + new Date()
    }
  },
}
</script>
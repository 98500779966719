export function listActions (that, subject) {
    if (!subject)
        return false

    const actionData = that.currentTable.actionData
    let result = []

    if(actionData) {
        if(actionData.entity)
        {
            result.push({
                data: {
                    name: "EntityEdit",
                    params: {
                        entityId: subject.id,
                        tableName: that.currentTable.url,
                        dbName: that.currentTable.db,
                    }
                },
                click: "url",
                type: "entity",
                name: "Edit",
            })
        }

        if(actionData.ban)
        {
            // todo: Ban function
            result.push({
                data: {

                },
                type: "ban",
                name: "Ban",
            })
        }

        if(actionData.delete)
        {
            let mains = that.currentTable.fields.filter((e) => e.main)
            if(mains.length) {
                mains = mains.map((e) => subject[e.name]).join(" ")
            }
            else {
                mains = `with id ${subject.id}`
            }


            result.push({
                data: {
                    text: "Delete entity "+`${mains}`,
                    yesFunc: function () {
                        that.$store.dispatch("deleteTableData", {
                            entityId: subject.id,
                            point: that.currentTable,
                            that: that,
                        })
                        that.$store.commit("toggleConfirm")
                    }.bind(that)
                },
                click: "confirm",
                type: "delete",
                name: "Delete",
            })
        }

        if(actionData.clone)
        {
            let mains = that.currentTable.fields.filter((e) => e.main)
            if(mains.length) {
                mains = mains.map((e) => subject[e.name]).join(" ")
            }
            else {
                mains = `with id ${subject.id}`
            }


            result.push({
                data: {
                    text: "Clone entity "+`${mains}`,
                    yesFunc: function () {
                        that.$store.dispatch("cloneTableData", {
                            entityId: subject.id,
                            point: that.currentTable,
                            that: that,
                        })
                        that.$store.commit("toggleConfirm")
                    }.bind(that)
                },
                click: "confirm",
                type: "clone",
                name: "Clone",
            })
        }

        if(actionData.recoverPassword)
        {
            // todo: recoverPassword function
            result.push({
                data: {

                },
                type: "recoverPassword",
                name: "Recover password",
            })
        }
    }

    return result
}
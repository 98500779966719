<template>
  <textarea class="border-0 placeholder-blueGray-300 rounded text-sm px-3 py-3
                  focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            :class="[bg, shadow, text].join(' ')"
            @input="upd($event)"
            rows="10"
            v-model="localVar"></textarea>
</template>

<script>
export default {
  name: "Textarea",
  data() {
    return {
      localVar: this.data
    }
  },
  props: {
    data: [Number, String, Object, Array],
    bg: {
      type: String,
      default: "bg-white"
    },
    shadow: {
      type: String,
      default: "shadow"
    },
    text: {
      type: String,
      default: "text-blueGray-600"
    },
    onchangefunc: {
      type: Function,
      default: () => {

      }
    },
  },
  computed: {

  },
  methods: {
    upd: function (e) {
      this.onchangefunc(e)
      const value = JSON.stringify(JSON.parse(e.target.value))
      this.$emit("changed", value)
    },
  }
}
</script>

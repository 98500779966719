<template>
  <div class="px-2 md:px-4 lg:px-10 mx-auto w-full py-24">

    <iframe width="100%" class="h-screen"
            :src="studioUrl"
            frameborder="0" style="border:0" allowfullscreen></iframe>

  </div>
</template>

<script>
export default {
  name: "GoogleDashboard",
  props: {
    contentModule: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    studioUrl: function () {
      return this.contentModule && this.contentModule.studio ? this.contentModule.studio : ""
    }
  }
}
</script>
<template>
  <textarea class="border-0 placeholder-blueGray-300 rounded text-sm px-3 py-3
                  focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            :class="[bg, shadow, text].join(' ')"
            :style="'width: ' + this.width + 'px;' + (bg === 'wb-bg-orange' ? ' background: #f6bba6;' :
         (bg === 'wb-bg-red' ? ' background: #F16063;' : '')) + ' ' +
          (height ? (height === 'auto' ? height : 'height: '+(height)+'px !important;') : '')"
            v-on:input="upd($event)"
            v-model="value"
            ref="textarea"
            @focus="resize"
            @keyup="resize"></textarea>
</template>

<script>
export default {
  name: "Textarea",
  props: {
    height: {
      type: [Number, String, Boolean],
      default: ""
    },
    width: [Number, String],
    value: [Number, String],
    index: [Number, String],
    bg: {
      type: String,
      default: "bg-white"
    },
    shadow: {
      type: String,
      default: "shadow"
    },
    text: {
      type: String,
      default: "text-blueGray-600"
    },
    onchangefunc: {
      type: Function,
      default: () => {

      }
    },
  },
  async created() {
    setTimeout(async function () {
      const { textarea } = this.$refs
      const initValue = textarea.value
      await this.$emit("input", "\n"+textarea.value)
      await this.$emit("input", initValue)
      await this.resize()
    }.bind(this), 100)
  },
  methods: {
    upd: function (e) {
      this.onchangefunc(e)
      this.$emit('input', e.target.value)
    },
    resize: async function () {
      const { textarea } = this.$refs
      await this.$emit("setAllHeight", "auto", this.index)
      await this.$emit("setAllHeight", textarea.scrollHeight, this.index)
      textarea.scrollTop = 0
    }
  }
}
</script>

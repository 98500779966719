<template>
  <div>
    <sidebar />
    <div class="relative md:ml-64 bg-blueGray-100">
      <admin-navbar />
      <div class="px-4 md:px-10 mx-auto w-full">
        <div class="flex flex-wrap pt-24">
          <div class="w-full mb-12 px-4">
            <div
                class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white"
            >
              <div class="rounded-t mb-0 px-4 py-3 border-0">
                <div class="flex flex-wrap items-center">
                  <div class="relative w-full px-4 max-w-full flex-grow flex-1">
                    <h3
                        class="font-semibold text-lg"
                    >
                      All admin accounts
                    </h3>
                  </div>
                </div>
              </div>
              <div v-if="admins.length" class="block w-full overflow-x-auto">
                <!-- Projects table -->
                <table class="items-center w-full border-collapse">
                  <thead>
                  <tr>
                    <th
                        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                    >
                      Email
                    </th>
                    <th
                        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                    >
                      Privileges
                    </th>
                    <th
                        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                    ></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(admin, index) in admins" :key="'admin' + index">
                    <th
                        class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center"
                    >
                      <img
                          src="https://www.gravatar.com/avatar/9205051007995646561d321241a6402_5?d=mystery"
                          class="h-12 w-12 bg-white rounded-full border"
                          alt="..."
                      />
                      <span
                          class="ml-3 font-bold"
                      >
                        {{ admin.email }}
                      </span>
                    </th>
                    <td
                        class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                    >
                      Button
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <Loader v-else />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminNavbar from "@/components/Navbars/AdminNavbar.vue";
import Sidebar from "@/components/Sidebar/Sidebar.vue";
import Loader from "@/components/Block/Loader";


export default {
  name: "Admins",
  components: {
    Sidebar, AdminNavbar, Loader
  },
  computed: {
    admins: function () {
      return this.$store.state.admins
    }
  },
  created() {
    this.$store.dispatch("getAdmins", {that: this,})
  },
}
</script>
<template>
  <div>
    <sidebar />
    <div class="relative md:ml-64 bg-blueGray-100">
      <admin-navbar />
      <TranslationsV1 v-if="version===1" :contentModule="contentModule" />
    </div>
  </div>
</template>
<script>
import AdminNavbar from "@/components/Navbars/AdminNavbar.vue";
import Sidebar from "@/components/Sidebar/Sidebar.vue";
import TranslationsV1 from "@/layouts/TranslationsV1";


export default {
  name: 'Dashboard',
  components: {
    TranslationsV1,
    AdminNavbar,
    Sidebar,
  },
  computed: {
    currentModule: function () {
      return this.$store.state.currentModule
    },
    contentModule: function () {
      return this.$store.state.currentModule.content ? this.$store.state.currentModule.content : {}
    },
    version: function () {
      return (this.contentModule && this.contentModule.version) ? this.contentModule.version : 0
    },
  },
  created() {
    this.$store.dispatch("getCurrentModule", {
      url: "translations",
      that: this,
    })
  }

};
</script>

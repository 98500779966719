<template>
  <div>
    <sidebar />
    <div class="relative md:ml-64 bg-blueGray-100">
      <admin-navbar />
      <div v-if="this.table"
           class="px-4 md:px-10 mx-auto w-full py-24 flex items-start justify-center gap-32 flex-col">
        <div>
          <Button colors="wb-bg-green text-white active:bg-emerald-600"
                  @wasClicked="backPage()"
                  class="flex gap-4 items-center justify-center">
            <ArrowLeftIcon />
            <span>Back</span>
          </Button>
        </div>

        <div>
          <h2 class="block">Table name</h2>

          <div class="flex items-center justify-start gap-4 mt-4">
            <div>{{ table.tablename }}</div>
            <Input type="text" placeholder="Name Verbose"
                   ref="verbose"
                   :value="table.verbose" />
            <Button @wasClicked="updatedTableName()">Save</Button>
          </div>
        </div>

        <div>
          <h2 class="block">Google SpreadSheet Id</h2>

          <div class="flex items-center justify-start gap-4 mt-4">
            <Input type="text" placeholder="Spreadsheet id"
                   ref="spreadsheet"
                   :value="table.spreadsheet_id" />
            <Button @wasClicked="updatedSpreadsheet()">Save</Button>
          </div>
        </div>

        <div class="w-full">
          <h2 class="block">Show columns</h2>

          <div class="grid xl:grid-cols-3 gap-8 items-start w-full mt-4">
            <div class="transition bg-blueGray-100 p-4
                        flex items-start justify-center gap-4 flex-col w-full border-b"
                 :style="'transform: ' + (dragged === fieldIndex ?
                 `translate(${pageX-dragCords[0]}px, ${pageY-dragCords[1]}px)` : '')"
                v-for="(field, fieldIndex) in table.fields" :key="'field'+fieldIndex">
              <div class="w-full cursor-pointer" @mousedown="dragElement($event, fieldIndex)">
                <ViewListIcon />
              </div>
              <div class="w-full">
                <div class="text-xs">
                  Column:
                </div>
                <select v-model="field.name"
                        @change="updatedFields"
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded
                         text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                  <option v-for="(optionColumn, indexOption) in columns"
                          :key="'optionColumn'+indexOption"
                          :value="optionColumn"
                          :selected="field.name===optionColumn">
                    {{optionColumn}}
                  </option>
                </select>
              </div>
              <div class="w-full">
                <div class="text-xs">
                  Type:
                </div>
                <select v-model="field.type"
                        @change="updatedFields"
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded
                         text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                  <option v-for="(optionType, indexOption) in typeOptions"
                          :key="'optionType'+indexOption"
                          :value="optionType"
                          :selected="field.type===optionType">
                    {{optionType}}
                  </option>
                </select>
              </div>
              <div v-if="field.type === 'id'" class="w-full">
                <div class="text-xs">
                  Table name:
                </div>
                <Input @input="updatedFields" v-model="field.id.table"
                       placeholder="Tablename" />

                <div class="text-xs mt-3">
                  Column name to find:
                </div>
                <Input @input="updatedFields" v-model="field.id.column"
                       placeholder="Column name" />

                <div class="w-full mt-2"
                     v-for="(getThing, indexGetThing) in field.id.get" :key="'getThing2'+indexGetThing">
                  <div class="text-xs">
                    What to get:
                  </div>
                  <Input @input="updatedFields" v-model="field.id.get[indexGetThing]"
                         placeholder="Column name" />
                  <div class="inline-flex">
                    <Button @wasClicked="removeGetThing2(fieldIndex, indexGetThing)"
                            size="0"
                            class="inline-flex items-center justify-center gap-2 p-2 mt-2"
                            colors="bg-red-500 text-white active:bg-red-600">
                      <TrashIcon />
                    </Button>
                  </div>
                </div>
                <Button @wasClicked="addGetThing2(fieldIndex)"
                        size="0"
                        class="inline-flex items-center justify-center gap-2 p-2 mt-2"
                        colors="wb-bg-green text-white active:bg-emerald-600">
                  <PlusIcon />
                </Button>
              </div>
              <div v-if="field.type === 'image' || field.type === 'url'" class="w-full">
                <div class="text-xs">
                  URL prefix part:
                </div>
                <Input @input="updatedFields" v-model="field.prefix" placeholder="https://site.com/"  />
              </div>
              <div v-if="field.type === 'image'" class="w-full">
                <div class="text-xs">
                  Background color:
                </div>
                <Input @input="updatedFields" v-model="field.background" placeholder="120"  />
              </div>
              <div v-if="field.type === 'text' || field.type === 'bigtext'" class="w-full">
                <div class="text-xs">
                  (is Json ?) Dict key:
                </div>
                <Input @input="updatedFields" v-model="field.dictkey" placeholder="en"  />
              </div>
              <div v-if="field.type === 'text' || field.type === 'bigtext'" class="w-full">
                <div class="text-xs">
                  Max length:
                </div>
                <Input @input="updatedFields" v-model="field.maxlen" placeholder="120"  />
              </div>
              <div v-if="field.type === 'select'" class="w-full">
                <div class="text-xs">
                  Select rule:
                </div>
                <Input @input="updatedFields" v-model="field.select" placeholder="category name"  />
              </div>
              <div v-if="field.type === 'sql'" class="w-full">
                <div class="text-xs">
                  Custom SQL:
                </div>
                <Input @input="updatedFields" v-model="field.sql" placeholder="SELECT * ..."  />
              </div>
              <div class="w-full">
                <div class="text-xs">
                  Label (Verbose):
                </div>
                <Input @input="updatedFields" v-model="field.verbose" placeholder="Verbose"  />
              </div>
              <div class="w-full">
                <div class="text-xs">
                  Custom css:
                </div>
                <Input @input="updatedFields" v-model="field.css" placeholder="color: red"  />
              </div>
              <div class="flex items-center justify-start gap-2 w-full">
                <input type="checkbox"
                       @change="updatedFields"
                       class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                       v-model="field.hidden"
                />
                <span class="text-xs">is hidden column?</span>
              </div>
              <div class="flex items-center justify-start gap-2">
                <Button @wasClicked="removeField(fieldIndex)"
                        size="0"
                        class="text-xs flex items-center justify-center gap-2 p-2"
                        colors="bg-red-500 text-white active:bg-red-600">
                  <TrashIcon />
                  <span>Remove</span>
                </Button>
                <Button @wasClicked="upField(fieldIndex)"
                        size="0"
                        class="text-xs flex items-center justify-center gap-2 p-2"
                        colors="bg-blue-500 text-white active:bg-blue-600">
                  <ArrowLeftIcon class="w-4 h-4" />
                  <span>Up</span>
                </Button>
                <Button @wasClicked="downField(fieldIndex)"
                        size="0"
                        class="text-xs flex items-center justify-center gap-2 p-2"
                        colors="bg-blue-500 text-white active:bg-blue-600">
                  <ArrowRightIcon class="w-4 h-4" />
                  <span>Down</span>
                </Button>
              </div>
            </div>
            <div class="flex items-start justify-center w-full">
               <Button @wasClicked="addField()" colors="wb-bg-green text-white active:bg-emerald-600">Add</Button>
            </div>
          </div>
        </div>

        <div>
          <Button colors="wb-bg-green text-white active:bg-emerald-600"
                  @wasClicked="backPage()"
                  class="flex gap-4 items-center justify-center">
            <ArrowLeftIcon />
            <span>Back</span>
          </Button>
        </div>

        <div class="w-full">
          <h2 class="block">Actions with table</h2>

          <div class="flex items-start justify-center gap-4 flex-col w-full mt-4">
            <div class="flex items-center justify-start gap-2 w-full">
              <input type="checkbox"
                     class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                     :checked="this.table.action_data && this.table.action_data.delete"
                     @change="updateMainAction($event, 'delete')"
              />
              <span class="text-xs">Delete</span>
            </div>

            <div class="flex items-center justify-start gap-2 w-full">
              <input type="checkbox"
                     class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                     :checked="this.table.action_data && this.table.action_data.clone"
                     @change="updateMainAction($event, 'clone')"
              />
              <span class="text-xs">Clone</span>
            </div>

            <div class="flex items-center justify-start gap-2 w-full">
              <input type="checkbox"
                     class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                     :checked="this.table.action_data && this.table.action_data.ban"
                     @change="updateMainAction($event, 'ban')"
              />
              <span class="text-xs">Ban</span>
            </div>

            <div class="flex items-center justify-start gap-2 w-full">
              <input type="checkbox"
                     class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                     :checked="this.table.action_data && this.table.action_data.recoverPassword"
                     @change="updateMainAction($event, 'recoverPassword')"
              />
              <span class="text-xs">Recover password</span>
            </div>

            <div class="flex items-center justify-start gap-2 w-full">
              <input type="checkbox"
                     class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                     :checked="this.table.action_data && this.table.action_data.filter"
                     @change="updateMainAction($event, 'filter')"
              />
              <span class="text-xs">Filter table</span>
            </div>

            <div class="w-full"
                 v-if="this.table.action_data && this.table.action_data.filter && this.table.action_data.filter.by">
              <div class="mb-3 text-xs">
                Filter by (column):
              </div>
              <select v-model="this.table.action_data.filter.by"
                      @change="updateMainAction($event, 'filterBy')"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded w-full
                         text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                <option v-for="(optionColumn, indexOption) in columns"
                        :key="'optionActionColumnFilter'+indexOption"
                        :value="optionColumn"
                        :selected="table.action_data.filter.by===optionColumn">
                  {{optionColumn}}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="w-full">
          <h2 class="block">Entity data</h2>

          <div class="grid xl:grid-cols-3 gap-8 w-full mt-4">
            <div class="transition bg-blueGray-100 p-4
                        flex items-start justify-start gap-4 flex-col w-full border-b"
                 v-for="(field, fieldIndex) in entityData" :key="'action_field'+fieldIndex">
              <div class="flex items-center justify-start gap-2 w-full">
                <input type="checkbox"
                       @change="updatedActionFields"
                       class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                       v-model="field.hidden"
                />
                <span class="text-xs">is hidden column?</span>
              </div>
              <div class="w-full">
                <div class="text-xs">
                  Column:
                </div>
                <select v-model="field.name"
                        @change="updatedActionFields"
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded
                         text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                  <option v-for="(optionColumn, indexOption) in columns"
                          :key="'optionActionColumn'+indexOption"
                          :value="optionColumn"
                          :selected="field.name===optionColumn">
                    {{optionColumn}}
                  </option>
                </select>
              </div>
              <div class="w-full">
                <div class="text-xs">
                  Type:
                </div>
                <select v-model="field.type"
                        @change="updatedActionFields"
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded
                         text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                  <option v-for="(optionType, indexOption) in typeActionOptions"
                          :key="'optionActionType'+indexOption"
                          :value="optionType"
                          :selected="field.type===optionType">
                    {{optionType}}
                  </option>
                </select>
              </div>
              <div v-if="field.type === 'select'" class="w-full">
                <div class="text-xs">
                  Select rule:
                </div>
                <Input @input="updatedActionFields" v-model="field.select"
                       placeholder="Write a selector table or rule like table.column:table.column (key:value)"  />
              </div>
              <div v-if="field.type === 'function'" class="w-full">
                <div class="text-xs">
                  Function name:
                </div>
                <Input @input="updatedActionFields" v-model="field.function"
                       placeholder="function without ()"  />
              </div>
              <div class="w-full">
                <div class="text-xs">
                  Label (Verbose):
                </div>
                <Input @input="updatedActionFields" v-model="field.verbose" placeholder="Verbose"  />
              </div>
              <div class="w-full">
                <div class="text-xs">
                  Placeholder:
                </div>
                <Input @input="updatedActionFields" v-model="field.placeholder"
                       placeholder="It's like a prompt, but in input field"  />
              </div>
              <div class="w-full">
                <div class="text-xs">
                  Prompt:
                </div>
                <Input @input="updatedActionFields" v-model="field.prompt" placeholder="It will help you"  />
              </div>
              <div class="w-full">
                <div class="text-xs">
                  Image Prompt?:
                </div>
                <Input @input="updatedActionFields" v-model="field.image_prompt" placeholder="Image URI"  />
              </div>
              <div class="w-full">
                <div class="text-xs">
                  Default value:
                </div>
                <Input @input="updatedActionFields" v-model="field.default"
                       :placeholder="'Default value. write in {{function}} to call functions'"  />
                <div class="mt-4" v-if="field.default">
                  <div>
                    List functions:
                  </div>
                  <ul class="text-xs list-disc mt-2">
                    <li>date_today</li>
                  </ul>
                </div>
              </div>
              <div class="w-full flex items-start justify-start gap-2 flex-col">
                <div class="text-xs">
                  Flags:
                </div>
                <div class="flex items-center justify-start gap-2"
                    v-for="(flag, indexFlag) in ['required', 'unique']" :key="'flag'+indexFlag">
                  <input type="checkbox"
                         @change="updatedActionFields"
                         class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                         :value="flag"
                         v-model="field.flags"
                  />
                  <span class="text-sm capitalize">{{flag}}</span>
                </div>
              </div>

              <div v-if="field.type === 'selectlist'"
                  class="w-full flex items-start justify-start gap-2 flex-col">
                <div class="text-xs">
                  List Settings:
                </div>
                <div class="flex items-center justify-start gap-2">
                  <input type="checkbox"
                         class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                         value="true"
                         @change="createListObject(fieldIndex)"
                         v-model="listFieldCollapse[fieldIndex]"
                  />
                  <span class="text-sm capitalize">Is it a list?</span>
                </div>

                <div v-if="listFieldCollapse[fieldIndex] || (field.where && field.list)"
                    class="border w-full p-3 mt-5">
                  <div>
                    List params:
                  </div>
                  <div class="w-full mt-2" v-if="field.list">
                    <div class="text-xs">
                      Class name:
                    </div>
                    <Input @input="updatedActionFields" v-model="field.list.class"
                           placeholder="Class name" />
                    <div class="text-xs mt-3">
                      Column ID name:
                    </div>
                    <Input @input="updatedActionFields" v-model="field.list.column"
                           placeholder="id" />
                    <div class="text-xs mt-3">
                      Output:
                    </div>
                    <Input @input="updatedActionFields" v-model="field.list.output"
                           placeholder="name_ru" />
                    <div class="text-xs mt-3">
                      Table name:
                    </div>
                    <Input @input="updatedActionFields" v-model="field.list.table"
                           placeholder="Table name" />
                    <div class="flex items-center justify-start gap-2 mt-3">
                      <input type="checkbox"
                             @change="updatedActionFields"
                             class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                             :value="true"
                             v-model="field.list.unique"
                      />
                      <span class="text-sm capitalize">Unique</span>
                    </div>

                    <div class="w-full mt-2"
                         v-for="(getThing, indexGetThing) in field.list.get" :key="'getThing'+indexGetThing">
                      <div class="text-xs">
                        What to get:
                      </div>
                      <Input @input="updatedActionFields" v-model="field.list.get[indexGetThing]"
                             placeholder="Column name" />
                      <div class="inline-flex">
                        <Button @wasClicked="removeGetThing(fieldIndex, indexGetThing)"
                                size="0"
                                class="inline-flex items-center justify-center gap-2 p-2 mt-2"
                                colors="bg-red-500 text-white active:bg-red-600">
                          <TrashIcon />
                        </Button>
                      </div>
                    </div>
                    <Button @wasClicked="addGetThing(fieldIndex)"
                            size="0"
                            class="inline-flex items-center justify-center gap-2 p-2 mt-2"
                            colors="wb-bg-green text-white active:bg-emerald-600">
                      <PlusIcon />
                    </Button>
                  </div>

                  <div class="mt-5">
                    Where:
                  </div>
                  <div class="w-full mt-2"
                      v-for="(whereThing, indexWhereThing) in field.where" :key="'whereThing'+indexWhereThing">
                    <div class="text-xs">
                      Column:
                    </div>
                    <Input @input="updatedActionFields" v-model="field.where[indexWhereThing].column"
                           placeholder="Column name"  />
                    <div class="text-xs mt-5">
                      Value:
                    </div>
                    <Input @input="updatedActionFields" v-model="field.where[indexWhereThing].value"
                           placeholder="Column value"  />
                    <div class="inline-flex">
                      <Button @wasClicked="removeWhereThing(fieldIndex, indexWhereThing)"
                              size="0"
                              class="inline-flex items-center justify-center gap-2 p-2 mt-2"
                              colors="bg-red-500 text-white active:bg-red-600">
                        <TrashIcon />
                      </Button>
                    </div>
                  </div>
                  <div class="inline-flex">
                    <Button @wasClicked="addWhereThing(fieldIndex)"
                            size="0"
                            class="inline-flex items-center justify-center gap-2 p-2 mt-2"
                            colors="wb-bg-green text-white active:bg-emerald-600">
                      <PlusIcon />
                    </Button>
                  </div>

                </div>
              </div>
              <div class="flex items-center justify-start gap-2 mt-10">
                <Button @wasClicked="removeActionField(fieldIndex)"
                        size="0"
                        class="text-xs flex items-center justify-center gap-2 p-2"
                        colors="bg-red-500 text-white active:bg-red-600">
                  <TrashIcon />
                  <span>Remove</span>
                </Button>
                <Button @wasClicked="upActionField(fieldIndex)"
                        size="0"
                        class="text-xs flex items-center justify-center gap-2 p-2"
                        colors="bg-blue-500 text-white active:bg-blue-600">
                  <ArrowLeftIcon class="w-4 h-4" />
                  <span>Up</span>
                </Button>
                <Button @wasClicked="downActionField(fieldIndex)"
                        size="0"
                        class="text-xs flex items-center justify-center gap-2 p-2"
                        colors="bg-blue-500 text-white active:bg-blue-600">
                  <ArrowRightIcon class="w-4 h-4" />
                  <span>Down</span>
                </Button>
              </div>
            </div>
            <div class="flex items-start justify-center w-full">
              <Button @wasClicked="addActionField()"
                      colors="wb-bg-green text-white active:bg-emerald-600">Add</Button>
            </div>
          </div>
        </div>

        <div>
          <Button colors="wb-bg-green text-white active:bg-emerald-600"
                  @wasClicked="backPage()"
                  class="flex gap-4 items-center justify-center">
            <ArrowLeftIcon />
            <span>Back</span>
          </Button>
        </div>

      </div>
      <Loader v-else />
    </div>
  </div>
</template>

<script>
import AdminNavbar from "@/components/Navbars/AdminNavbar.vue";
import Sidebar from "@/components/Sidebar/Sidebar.vue";
import nvgpost from "@/nvgpost";
import Loader from "@/components/Block/Loader";
import Input from "@/components/Inter/Input";
import Button from "@/components/Inter/Button";
import {ArrowLeftIcon, ArrowRightIcon, TrashIcon, ViewListIcon, PlusIcon} from "@vue-hero-icons/outline";


export default {
  name: "SettingsTable",
  components: {
    Button,
    Input,
    Loader,
    Sidebar, AdminNavbar,
    ArrowLeftIcon, ArrowRightIcon, TrashIcon, ViewListIcon, PlusIcon,
  },
  async created() {
    await this.initColumns()

    window.addEventListener('mousemove', this.moveMouse)
    window.addEventListener('mouseup', this.dropElement)
  },
  destroyed: function() {
    window.removeEventListener('mousemove', this.moveMouse)
    window.removeEventListener('mouseup', this.dropElement)
  },
  data() {
    return {
      typeOptions: [
        "text", "bigtext", "bool", "id", "image", "youtube_url", "url", "select", "timedeltadays", "time", "sql"
      ],
      typeActionOptions: [
        "text", "bigtext", "markdown", "password", "image", "files", "youtube", "youtubelist", "select", "selectlist", "list", "dict", "checkbox", "date", "json", "function", "test"
      ],
      table: {},
      columns: [],
      dragged: false,
      dragCords: [0, 0],
      pageX: 0,
      pageY: 0,
      listFieldCollapse: []
    }
  },
  computed: {
    currentTableUrl: function () {
      return this.$route.params.tableName
    },
    currentDBUrl: function () {
      return this.$route.params.dbName
    },
    fields: function () {
      const a = this.table.fields
      return a ? [...a] : []
    },
    entityData: function () {
      return this.table && this.table.action_data && this.table.action_data.entity
          ? [...this.table.action_data.entity.fields] : []
    }
  },
  methods: {
    backPage: function () {
      this.$router.push({name: 'Table', params: {dbName: this.currentDBUrl, tableName: this.currentTableUrl}})
    },
    initColumns: async function() {
      await nvgpost(this.$apiEndpoint+"/v1/settings",
          "GET",
          {
            table: this.currentTableUrl,
          },
          {}, (res)=>{
            if(res.data) {
              this.table = res.data.table
              this.columns = res.data.columns
            }
          }, true)
    },
    createListObject: function (indexField) {
      if(!this.table.action_data.entity.fields[indexField].list) {
        this.table.action_data.entity.fields[indexField].list = {}
      }

      if(this.table.action_data.entity.fields[indexField].list
          && !this.table.action_data.entity.fields[indexField].list.class) {
        this.table.action_data.entity.fields[indexField].list.class = ""
        this.table.action_data.entity.fields[indexField].list.column = ""
        this.table.action_data.entity.fields[indexField].list.output = ""
        this.table.action_data.entity.fields[indexField].list.table = ""
        this.table.action_data.entity.fields[indexField].list.unique = undefined
        this.table.action_data.entity.fields[indexField].list.get = []
        this.table = Object.assign({}, this.table)
      }
    },
    removeWhereThing: function (indexField, indexWhere) {
      this.table.action_data.entity.fields[indexField].where =
          this.table.action_data.entity.fields[indexField].where.filter((e,i) => i !== indexWhere)
      this.table = Object.assign({}, this.table)
      this.updatedActionFields()
    },
    addWhereThing: function (indexField) {
      this.table.action_data.entity.fields[indexField].where
          = [...this.table.action_data.entity.fields[indexField].where]

      this.table.action_data.entity.fields[indexField].where.push({
        column: "",
        value: ""
      })
      this.table = Object.assign({}, this.table)
    },
    removeGetThing: function (indexField, indexGet) {
      this.table.action_data.entity.fields[indexField].list.get =
          this.table.action_data.entity.fields[indexField].list.get.filter((e,i) => i !== indexGet)
      this.table = Object.assign({}, this.table)
      this.updatedActionFields()
    },
    addGetThing: function (indexField) {
      this.table.action_data.entity.fields[indexField].list.get.push("")
      this.table = Object.assign({}, this.table)
    },
    removeGetThing2: function (indexField, indexGet) {
      this.table.fields[indexField].id.get =
          this.table.fields[indexField].id.get.filter((e,i) => i !== indexGet)
      this.table = Object.assign({}, this.table)
      this.updatedFields()
    },
    addGetThing2: function (indexField) {
      this.table.fields[indexField].id.get.push("")
      this.table = Object.assign({}, this.table)
    },
    updatedTableName: async function () {
      const value = this.$refs.verbose.$el.value

      await nvgpost(this.$apiEndpoint+"/v1/settings",
          "PUT",
          {
            table: this.currentTableUrl,
            name: value
          },
          {}, (res)=>{
            if(res.data) {
              this.table = res.data.table
              this.columns = res.data.columns
            }
          }, true)
    },
    updatedSpreadsheet: async function () {
      const value = this.$refs.spreadsheet.$el.value

      await nvgpost(this.$apiEndpoint+"/v1/settings",
          "PUT",
          {
            table: this.currentTableUrl,
            spreadsheet: value
          },
          {}, (res)=>{
            if(res.data) {
              this.table = res.data.table
              this.columns = res.data.columns
            }
          }, true)
    },
    updatedActionFields: function () {
      nvgpost(this.$apiEndpoint+"/v1/settings",
          "PUT",
          {
            table: this.currentTableUrl,
            entityData: this.entityData
          },
          {}, (res)=>{
            if(res.data) {
              this.table = res.data.table
              this.columns = res.data.columns
            }
          }, true)
    },
    updatedActionFieldsTable: function () {
      nvgpost(this.$apiEndpoint+"/v1/settings",
          "PUT",
          {
            table: this.currentTableUrl,
            actionData: this.table.action_data
          },
          {}, (res)=>{
            if(res.data) {
              this.table = res.data.table
              this.columns = res.data.columns
            }
          }, true)
    },
    addActionField: function () {
      if(!this.table.action_data) {
        this.table.action_data = {
          entity: {}
        }
      }
      if(!this.table.action_data.entity || !Object.keys(this.table.action_data.entity).length) {
        this.table.action_data.entity = {
          fields: []
        }
      }

      this.table.action_data.entity.fields.push({
        name: this.columns[0],
        type: "text",
        flags: [],
      })
      this.table = Object.assign({}, this.table)
    },
    removeActionField: function (indexField) {
      this.table.action_data.entity.fields = [...this.entityData.filter((e,i) => i !== indexField)]
      this.updatedActionFields()
      this.table = Object.assign({}, this.table)
    },
    updateMainAction: function (event, action) {
      if(!this.table.action_data || !Object.keys(this.table.action_data).length) {
        this.table.action_data = {}
      }

      switch (action) {
        case "filter":
          if(event.target.checked)
            this.table.action_data[action] = {"by": "timestamp"}
          else
            delete this.table.action_data[action]
          break;
        case "filterBy":
          this.table.action_data.filter.by = event.target.value
          break;
        default:
          this.table.action_data[action] = event.target.checked
          break;
      }

      this.table.action_data = Object.assign({}, this.table.action_data)
      this.updatedActionFieldsTable()
    },
    updatedFields: function () {
      for(let i = 0; i < this.fields.length; i++) {
        if(this.fields[i].type === 'id' && !this.fields[i].id) {
          this.table.fields[i].id = {
            get: []
          }
        }
      }

      nvgpost(this.$apiEndpoint+"/v1/settings",
          "PUT",
          {
            table: this.currentTableUrl,
            fields: this.fields
          },
          {}, (res)=>{
            if(res.data) {
              this.table = res.data.table
              this.columns = res.data.columns
            }
          }, true)
    },
    addField: function () {
      if(!this.table.fields) {
        this.table.fields = []
      }

      this.table.fields.push({
        name: this.columns[0],
        type: "text"
      })
      this.table = Object.assign({}, this.table)
    },
    removeField: function (indexField) {
      this.table.fields = [...this.table.fields.filter((e,i) => i !== indexField)]
      this.updatedFields()
      this.table = Object.assign({}, this.table)
    },
    moveMouse: function (e) {
      if(this.dragged !== false || this.pageX === 0 && this.pageY === 0) {
        this.pageX = e.pageX
        this.pageY = e.pageY
      }
    },
    dropElement: function () {
      if(this.dragged !== false) {
        this.dragged = false
        this.dragCords = [0, 0]
        document.body.classList.remove('select-none')
      }
    },
    dragElement: function (e, indexField) {
      return false
      // eslint-disable-next-line no-unreachable
      this.dragged = indexField
      this.dragCords = [e.pageX, e.pageY]
      document.body.classList.add('select-none')
    },
    upField: function (indexField) {
      if (indexField-1 < 0) {
        return false
      }
      this.table.fields.splice(indexField-1, 0, this.table.fields.splice(indexField, 1)[0])
      this.updatedFields()
      this.table = Object.assign({}, this.table)
    },
    downField: function (indexField) {
      if (indexField+1 >= this.table.fields.length) {
        return false
      }
      this.table.fields.splice(indexField+1, 0, this.table.fields.splice(indexField, 1)[0])
      this.updatedFields()
      this.table = Object.assign({}, this.table)
    },
    upActionField: function (indexField) {
      if (indexField-1 < 0) {
        return false
      }
      this.table.action_data.entity.fields.splice(indexField-1, 0, this.table.action_data.entity.fields.splice(indexField, 1)[0])
      this.updatedActionFields()
      this.table = Object.assign({}, this.table)
    },
    downActionField: function (indexField) {
      if (indexField+1 >= this.table.action_data.entity.fields.length) {
        return false
      }
      this.table.action_data.entity.fields.splice(indexField+1, 0, this.table.action_data.entity.fields.splice(indexField, 1)[0])
      this.updatedActionFields()
      this.table = Object.assign({}, this.table)
    }
  }
}
</script>
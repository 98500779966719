import Vue from "vue"
import VueRouter from "vue-router"
import store from '@/store'
import Login from "@/views/Login";
import Dashboard from "@/views/Dashboard";
import Settings from "@/views/Settings";
import EntityEdit from "@/views/EntityEdit";
import Table from "@/views/Table";
import Admins from "@/views/Admins";
import Translations from "@/views/Translations";
import ImageStorage from "@/views/ImageStorage";
import SettingsTable from "@/views/SettingsTable";


Vue.use(VueRouter)
const router = new VueRouter({
    mode: "history",
    scrollBehavior () {
        return { x: 0, y: 0 }
    },
    routes: [
        {
            path: "/",
            component: Login,
            name: "Start",
        },
        {
            path: "/dashboard",
            component: Dashboard,
            name: "Dashboard",
            //alias: ['/analytics']
        },
        {
            path: "/analytics",
            component: Dashboard,
            name: "Dashboard"
        },
        {
            path: "/settings",
            component: Settings,
            name: "Settings"
        },
        {
            path: "/settings/table/:dbName/:tableName",
            component: SettingsTable,
            name: "SettingsTable"
        },
        {
            path: "/translations",
            component: Translations,
            name: "Translations"
        },
        {
            path: "/admins",
            component: Admins,
            name: "Admins"
        },
        {
            path: "/images",
            component: ImageStorage,
            name: "ImageStorage"
        },
        {
            path: "/tables/:dbName/:tableName/",
            component: Table,
            name: "Table",
        },
        {
            path: "/tables/:dbName/:tableName/:entityId",
            component: EntityEdit,
            name: "EntityEdit"
        },

        { path: '/:catchAll(.*)', redirect: '/' }
    ]
})

router.beforeEach(async (to, from, next) => {
    const publicPages = ['',]
    let turl = to.path.split("/")[1]
    const authRequired = !publicPages.includes(turl)

    if(!store.getters.isAuthenticated)
        await store.dispatch("getUser")
    let loggedIn = store.getters.isAuthenticated

    let temp = localStorage.getItem("backcomeUrl")

    if (authRequired && !loggedIn)
    {
        localStorage.setItem("backcomeUrl", to.path)
        return next('/')
    }
    else if (temp && !!loggedIn && temp !== "/dashboard")
    {
        localStorage.removeItem("backcomeUrl")
        return next(temp)
    }
    else if (['', ].includes(turl) && !!loggedIn)
    {
        return next('/dashboard')
    }
    next()
})

export default router
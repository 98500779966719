<template>
  <div class="px-2 md:px-4 lg:px-10 py-24" v-if="charts">

    <div class="flex items-center justify-start gap-4 relative">
      <div class="border border-black p-4">
        <div>
          New Users
        </div>
        <div class="text-2xl font-medium text-green-400">
          {{this.charts.newUsers.count}}
        </div>
      </div>

      <div class="border border-black p-4">
        <div>
          Active Users
        </div>
        <div class="text-2xl font-medium text-blue-400">
          {{this.charts.activeUsers.count}}
        </div>
      </div>

      <div class="border border-black p-4">
        <div>
          Test passed
        </div>
        <div class="text-2xl font-medium text-red-400">
          {{this.charts.newAnswers.count}}
        </div>
      </div>

      <div class="absolute top-4 right-0 flex items-start flex-col gap-2">
        <span>Filter <span class="text-xs">(by time period)</span>:</span>
        <select v-model="filterDate">
          <option value="1">All time</option>
          <option value="2">30 Days</option>
          <option value="3">7 Days</option>
          <option value="4">Yesterday</option>
        </select>
      </div>
    </div>

    <div class="grid grid-cols-2 gap-4 mt-4">
      <div>
        New Users
        <GChart
            class="w-full"
            type="ColumnChart"
            :data="this.barChart"
            :options="this.chartOptions"
        />
      </div>
      <div>
        Active Users
        <GChart
            class="w-full"
            type="LineChart"
            :data="this.lineChart"
            :options="this.chartOptions"
        />
      </div>
      <div>
        Test passed
        <GChart
            class="w-full"
            type="PieChart"
            :data="this.pieChart"
            :options="this.chartOptions"
        />
      </div>
    </div>

    <div class="grid grid-cols-2 gap-8 mt-12">
      <div class="border border-black p-4 flex items-start justify-start gap-2 flex-col">
        <div class="text-lg my-2">
          <b>Top Performing Users</b>
        </div>
        <div class="grid grid-cols-2 border border-black w-full text-center">
          <div class="border border-black p-2 w-full">
            <b>User</b>
          </div>
          <div class="border border-black p-2 w-full">
            <b>% of passed tests</b>
          </div>
        </div>
        <div class="grid grid-cols-2 border border-black w-full text-center"
             v-for="(value, index) in charts.topusers" :key="'topuser'+index">
          <div class="border border-black p-2 w-full">
            {{value.name}}
          </div>
          <div class="border border-black p-2 w-full">
            {{value.correct}}
          </div>
        </div>
      </div>
      <div class="border border-black p-4 flex items-start justify-start gap-2 flex-col">
        <div class="text-lg my-2">
          <b>Most Passed Tests</b>
        </div>
        <div class="grid grid-cols-2 border border-black w-full text-center">
          <div class="border border-black p-2 w-full">
            <b>Test</b>
          </div>
          <div class="border border-black p-2 w-full">
            <b>% Success Pass</b>
          </div>
        </div>
        <div class="grid grid-cols-2 border border-black w-full text-center"
             v-for="(value, index) in charts.toptests" :key="'toptest'+index">
          <div class="border border-black p-2 w-full">
            {{value.name}}
          </div>
          <div class="border border-black p-2 w-full">
            {{value.proc2}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GChart } from 'vue-google-charts/legacy'

export default {
  name: "WeboaDashboard",
  components: {
    GChart
  },
  created() {
    this.$store.dispatch("getChartsDashboard", {
      filterDate: this.filterDate,
      that: this,
    })
  },
  props: {
    contentModule: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    metric: function () {
      return this.contentModule && this.contentModule.metric ? this.contentModule.metric : false
    },
    charts() {
      return Object.keys(this.$store.state.chartsDashboard).length ? this.$store.state.chartsDashboard : false
    },
    barChart() {
      if(!this.charts) {
        return [[]]
      }

      let result = [
        ['Date', 'Users'],
      ]

      Object.keys(this.charts.newUsers.chart).forEach(function(k) {
        result.push([k, this.charts.newUsers.chart[k]])
      }.bind(this))

      return result
    },
    lineChart() {
      if(!this.charts) {
        return [[]]
      }

      let result = [
        ['Date', 'Users'],
      ]

      Object.keys(this.charts.activeUsers.chart).forEach(function(k) {
        result.push([k, this.charts.activeUsers.chart[k]])
      }.bind(this))

      return result
    },
    pieChart() {
      if(!this.charts) {
        return [[]]
      }

      let result = [
        ['Passed', 'Count'],
      ]

      Object.keys(this.charts.newAnswers.chart).forEach(function(k) {
        result.push([k, this.charts.newAnswers.chart[k]])
      }.bind(this))

      return result
    }
  },
  watch: {
    filterDate() {
      this.$store.dispatch("getChartsDashboard", {
        filterDate: this.filterDate,
        that: this,
      })
    }
  },
  data() {
    return {
      filterDate: "1",
      chartOptions: {
        lineChart: {
          //title: 'Net Profit',
          //subtitle: 'Let\'s see how it\'s changed',
          curveType: 'function',
          height: 500,
          lineWidth: 3,
          pointsVisible: true,
          pointSize: 8,
          tooltip: {
            textStyle: {
              fontSize: 16
            },
          },
          focusTarget: "category",
          ignoreBounds: true,
          legend: {
            position: "bottom",
            textStyle: {
              fontSize: 16
            },
            alignment: "start"
          },
          fontSize: 20,
          chartArea: {
            width: '60%',
            height: '50%',
            top: 80
          },
          titlePosition: 'out',
          axisTitlesPosition: 'out',
          hAxis: {
            showTextEvery: '1',
            textPosition: 'out',
            textStyle: {
              fontSize: 11
            }
          },
          vAxis: {
            textPosition: 'out',
            textStyle: {
              fontSize: 16
            }
          }
        },
        pie: {
          //title: 'Taxes in months',
        }
      }
    }
  }
}
</script>
<template>
  <button class="font-bold uppercase
                  rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full
                  ease-linear transition-all duration-150"
          type="button"
          :class="
          (this.size === 'Lg' ? 'px-6 py-3' : '') + ' '
          + (this.size === 'Sm' ? 'px-6 py-3' : '') + ' '
          + (this.size === '0' ? 'p-0' : '') + ' '
          + (this.colors) + ' '
          "
          @click="$emit('wasClicked')">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "Button",
  props: {
    size: {
      type: String,
      default: "Sm",
    },
    colors: {
      type: String,
      default: "bg-blueGray-800 text-white active:bg-blueGray-600",
    },
    font: {
      type: String,
      default: "text-sm"
    }
  }
}
</script>

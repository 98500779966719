<template>
  <div class="px-2 md:px-4 lg:px-10 mx-auto min-h-screen py-24" :style="{backgroundColor: background}">
    <div v-if="logo" class="">
      <img :src="logo" alt="Logo" class="w-full h-full object-cover" />
    </div>
  </div>
</template>

<script>
export default {
  name: "WeboaLogoPage",
  props: {
    contentModule: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    logo: function () {
      return this.contentModule && this.contentModule.logo ? this.contentModule.logo : false
    },
    background: function () {
      return this.contentModule && this.contentModule.background ? this.contentModule.background : false
    }
  }
}
</script>
<template>
  <div class="px-2 md:px-4 lg:px-10 mx-auto w-full py-24">
    <div v-if="this.dict" class="pb-24">
      <div class="flex items-center justify-start gap-5 sticky top-0 z-10 shadow-md bg-white px-10 py-5">
        <div>
          <Button v-if="!isSaving" @wasClicked="saveDict()">Save</Button>
          <Loader class="transform scale-50" flex="flex justify-center items-center" margin=""
                  loader="width: 50px; height: 50px"
                  style="height: 40px" v-else />
        </div>
        <b>
          {{ dict.length }} strings
        </b>
        <span>-</span>
        <b>
          {{ totalDict.length }} strings
        </b>
        <b class="wb-text-green" v-if="dict.filter((e) => !e.id).length > 0">
          {{ dict.filter((e) => !e.id).length }} added
        </b>
        <b class="wb-text-red" v-if="difference_deleted(dict).length > 0">
          {{ difference_deleted(dict).length }} deleted
        </b>
        <b class="wb-text-orange" v-if="changes.length">
          {{ changes.length }} changes
        </b>
      </div>

      <div class="flex items-start justify-start gap-8 overflow-auto mt-8 pb-8 px-4">
        <div class="flex items-center justify-center gap-8 flex-col">
          <h4 class="uppercase text-xl font-bold shadow-md bg-white h-16 flex items-center justify-center w-full">
            Variable
          </h4>
          <Button @wasClicked="addNewTranslation()" colors="text-white wb-bg-green">
            Add new
          </Button>
          <div class="flex items-start justify-start gap-2 flex-col"
               v-for="(vara, indexVar) in dict" :key="'vara'+indexVar">
            <div class="flex items-center justify-start gap-2 text-xs">
              <div class="flex items-center justify-start gap-1">
                <input class="pointer-events-none opacity-0 invisible" type="radio" />
              </div>
            </div>
            <Input width="220"
                   :style="vara.height ? 'height: '+vara.height+'px;' : ''"
                   :bg="vara.deleted ? 'wb-bg-red' :
              (isStateDefault(vara, totalDict, 'name') ? 'bg-white' : 'wb-bg-orange')"
                   type="text" v-model="vara.name" :readonly="vara.deleted"  :disabled="vara.deleted"
                    />
          </div>
          <Button @wasClicked="addNewTranslation(2)" colors="text-white wb-bg-green">
            Add new
          </Button>
        </div>
        <div class="flex items-center justify-center gap-8 flex-col relative w-full"
             v-for="(lang, indexLang) in contentModule.langs" :key="'lang'+indexLang">
          <h4 class="uppercase text-xl font-bold shadow-md bg-white h-16 flex items-center justify-center w-full">
            {{lang}} {{ getFlagEmoji(lang) }}
          </h4>
          <Button @wasClicked="addNewTranslation()" colors="text-white wb-bg-green">
            Add new
          </Button>
          <div class="flex items-end justify-start gap-1 w-full"
              v-for="(field, indexField) in dict" :key="'field'+indexLang+'_'+indexField">
            <div class="cursor-pointer group relative p-2" @click="deleteTranslation(field.name, field.deleted)">
              <span class="transition group-hover:opacity-0">
                {{ getFlagEmoji(lang) }}
              </span>
              <span class="absolute -left-2 transition opacity-0 group-hover:opacity-100 w-3"
                    :class="{'wb-text-red': !field.deleted, 'wb-text-green': field.deleted}">
                <RefreshIcon v-if="field.deleted" />
                <XIcon v-else />
              </span>
            </div>
            <div class="flex items-start justify-start gap-2 flex-col w-full">
              <div class="flex items-center justify-start gap-2 text-xs">
                <div class="flex items-center justify-start gap-1">
                  <input :id="'fieldLine' + indexField + lang" type="radio"
                         value="line" :name="'fieldRadio' + indexField + lang"
                         :checked="field.type === 'line'" v-model="field.type" @change="nullHeight(indexField)" />
                  <label :for="'fieldLine' + indexField + lang">Line</label>
                </div>
                <div class="flex items-center justify-start gap-1">
                  <input :id="'fieldText' + indexField + lang" type="radio"
                         value="text" :name="'fieldRadio' + indexField + lang" v-model="field.type" />
                  <label :for="'fieldText' + indexField + lang">Text</label>
                </div>
                <div class="flex items-center justify-start gap-1">
                  <input :id="'fieldMD' + indexField + lang" type="radio"
                         value="md" :name="'fieldRadio' + indexField + lang" v-model="field.type" />
                  <label :for="'fieldMD' + indexField + lang">Markdown</label>
                </div>
              </div>
              <Input v-if="field.type==='line'" class="w-full" :bg="field.deleted ? 'wb-bg-red' :
                (isStateDefault(field, totalDict, lang) ? 'bg-white' : 'wb-bg-orange')"
                     type="text" v-model="field[lang]" :readonly="field.deleted" :disabled="field.deleted" />
              <Textarea v-if="field.type==='text'" width="400" class="overflow-hidden resize-none"
                        :bg="field.deleted ? 'wb-bg-red' :
                        (isStateDefault(field, totalDict, lang) ? 'bg-white' : 'wb-bg-orange')"
                        @setAllHeight="setAllHeight" :index="indexField" :height="field.height ? field.height : false"
                        :readonly="field.deleted" :disabled="field.deleted" v-model="field[lang]" :value="field[lang]" />
            </div>
          </div>
          <Button @wasClicked="addNewTranslation(2)" colors="text-white wb-bg-green">
            Add new
          </Button>
        </div>
      </div>
    </div>
    <Loader v-else />
  </div>
</template>

<script>
import { getFlagEmoji } from "@/helpers/flagEmoji";
import { difference, difference_deleted } from "@/helpers/difference";
import Loader from "@/components/Block/Loader";
import Button from "@/components/Inter/Button";
import Input from "@/components/Inter/Input";
import Textarea from "@/components/Inter/Textarea";
import {XIcon, RefreshIcon} from "@vue-hero-icons/solid";


export default {
  name: "TranslationsV1",
  components: {Button, Loader, Input, Textarea, XIcon, RefreshIcon},
  data() {
    return {
      dict: false,
      isSaving: false,
    }
  },
  computed: {
    totalDict: function () {
      return [...this.$store.state.dict].reverse()
    },
    changes: function () {
      return difference(this.totalDict, this.dict)
    },
    changes2: function () {
      return difference(this.dict, this.totalDict)
    }
  },
  methods: {
    difference_deleted: function (arr1, arr2) {
      return difference_deleted(arr1, arr2)
    },
    nullHeight: function (indexField) {
      this.setAllHeight(undefined, indexField)
    },
    setAllHeight: function (sHeight, index) {
      this.dict[index].height = sHeight
    },
    isStateDefault: function (field, totalDict, valstring) {
      const totDic = totalDict.filter((e) => e.id === field.id)[0]
      return (totDic && field[valstring] === totDic[valstring])
             || (!totDic && field.name)
    },
    getFlagEmoji: function (a) {
      return getFlagEmoji(a)
    },
    addNewTranslation: function (atTheEnd) {
      let obj = {
        id: false,
        name: "",
        type: "line",
      }
      for(let lang in this.contentModule.langs) {
        obj[this.contentModule.langs[lang]] = ""
      }

      if(atTheEnd === 2)
        this.dict.push(obj)
      else
        this.dict.unshift(obj)

      let temp_dict = [...this.dict]
      this.dict = []
      setTimeout(function () {
        this.dict = [...temp_dict]
      }.bind(this), 10)
    },
    deleteTranslation: function (name, deleted) {
      this.$store.commit("dataConfirm", {
        text: ((deleted) ? "Restore string " : "Delete string ")+name,
        yesFunc: function () {
          this.dict = this.dict.map((e) => {
            if(e.name === name) {
              if(deleted) {
                delete e.deleted
              }
              else {
                e.deleted = true
              }
            }
            return e
          })
          this.dict = JSON.parse(JSON.stringify([...this.dict]))
          this.$store.commit("toggleConfirm")
        }.bind(this)
      })
      this.$store.commit("toggleConfirm")
    },
    saveDict: async function () {
      if(this.dict.filter((e) => !e.name).length)
      {
        this.$root.$emit("alert", 'You cannot have empty variables for translations!', 2)
        return false
      }

      let valueArr = this.dict.map(function(item){ return item.name })
      let isDuplicate = valueArr.some(function(item, idx){
        return valueArr.indexOf(item) !== idx
      })

      if(isDuplicate) {
        this.$root.$emit("alert", 'You cannot have duplicates variables for translations!', 2)
        return false
      }

      this.isSaving = true
      await this.$store.dispatch("saveDict", {
        that: this,
        dict: this.changes2
      })
      await this.initDict(1)
    },
    initDict: async function(withoutRequest) {
      if(!withoutRequest)
        await this.$store.dispatch("getDict", {that: this,})
      this.dict = JSON.parse(JSON.stringify([...this.$store.state.dict])).reverse()
          .map(obj => ({ ...obj, type: 'line' }))
      this.isSaving = false
    }
  },
  props: {
    contentModule: {
      type: Object,
      default: () => {}
    }
  },
  async created() {
    await this.initDict()
  },
}
</script>
<template>
  <div>
    <sidebar />
    <div class="relative md:ml-64 bg-blueGray-100">
      <admin-navbar />
      <div class="px-4 md:px-10 mx-auto w-full py-24 flex items-start justify-center gap-8 flex-col">
        <div class="w-full">
          <h2 class="block">Change password</h2>

          <div class="mt-10 w-1/2">
            <Input type="password" v-model="password" placeholder="New password" />
            <Input type="password" v-model="password2" placeholder="Confirm password" class="mt-2" />
            <Button class="mt-4" @wasClicked="confirmChange()">
              Change
            </Button>
          </div>
        </div>

        <div>
          <h2 class="block mb-4">Setup logo</h2>

          <Button
              class="border border-dashed tt-border-gray py-3 cursor-pointer
                inline-flex items-center justify-center flex-col gap-2 relative">
            <span>Upload</span>
            <input type="file" class="cursor-pointer absolute top-0 z-10 left-0 w-full h-full opacity-0"
                   id="fullUploaderInput" accept="image/*" @change="changeCallback($event, 'fullUploaderInput')">
          </Button>
        </div>

        <div>
          <h2 class="block">Setup tables</h2>

          <div class="grid xl:grid-cols-6 gap-4 mt-8">
            <div class="flex items-center justify-start gap-4"
                 v-for="(table, tableIndex) in tables" :key="'table'+tableIndex">
              <TToggle v-model="tablesToggles[tableIndex]" />
              <span>{{table}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminNavbar from "@/components/Navbars/AdminNavbar.vue";
import Sidebar from "@/components/Sidebar/Sidebar.vue";
import Input from "@/components/Inter/Input";
import Button from "@/components/Inter/Button";
import nvgpost from "@/nvgpost";


export default {
  name: "Settings",
  components: {
    Button,
    Input,
    Sidebar, AdminNavbar
  },
  async created() {
    await this.$store.dispatch("getTablesAll", {that: this})
    await this.initTables()
  },
  data() {
    return {
      password: "",
      password2: "",
      tablesToggles: [],
      watchingTablesToggles: false,
      timerTable: false,
      imagePreview: ""
    }
  },
  watch: {
    tablesVisible: function () {
      this.initTables()
    },
    tablesSelected: function (newValue) {
      if(this.watchingTablesToggles)
      {
        if(this.timerTable)
          clearTimeout(this.timerTable)
        this.timerTable = setTimeout(function () {
          nvgpost(this.$apiEndpoint+"/v1/tables",
              "PUT",
              {
                tables: newValue,
              },
              {}, (res)=>{
                if(!res.data) {
                  this.$root.$emit("alert", "Tables list change error", 2)
                }
              }, true)
        }.bind(this), 1000)
      }
    },
  },
  computed: {
    tables: function () {
      return this.$store.state.tablesList
    },
    tablesVisible: function () {
      let t = this.$store.state.tables
      if(t && t[Object.keys(t)[0]])
        return t[Object.keys(t)[0]].map(e => e.name)
      return []
    },
    tablesSelected: function () {
      return this.tables.filter((e, i) => !!this.tablesToggles[i])
    }
  },
  methods: {
    initTables: async function () {
      this.tablesToggles = this.tables.map(e => this.tablesVisible.includes(e))
      setTimeout(function () {
        this.watchingTablesToggles = true
      }.bind(this), 100)
    },
    changeCallback: function (event) {
      let selectedFiles = event.target.files
      for(let i = 0; i < selectedFiles.length; i++) {
        let selectedFile = selectedFiles[i]
        let reader = new FileReader()
        reader.onload = function(event) {
          if (event.target.result.match('image.*') || event.target.result.match('image/*'))
          {
            this.imagePreview = event.target.result

            nvgpost(this.$apiEndpoint+"/v1/weboalogo",
                "POST",
                {
                  image: this.imagePreview,
                },
                {}, (res)=>{
                  if(res.data) {
                    this.imagePreview = false
                    this.$root.$emit("alert", "Image uploaded successfully!", 1)
                  }
                  else {
                    this.$root.$emit("alert", "Image upload Error", 2)
                  }
                }, true)
          }
        }.bind(this)
        setTimeout(function(){
          reader.readAsDataURL(selectedFile)
        }.bind(this),150)
      }
    },
    confirmChange: function () {
      if(this.password !== this.password2)
      {
        this.$root.$emit("alert", "Passwords don't match!", 2)
        return false
      }

      this.$store.commit("toggleConfirm")
      this.$store.commit("dataConfirm", {
        text: "Change password",
        yesFunc: function () {
          this.changePassword()
          this.$store.commit("toggleConfirm")
        }.bind(this)
      })
    },
    changePassword: function () {
      nvgpost(this.$apiEndpoint+"/v1/password",
          "POST",
          {
            password: this.password,
          },
          {}, (res)=>{
            if(res.data) {
              this.$root.$emit("alert", "Password changed successfully!", 1)
            }
            else {
              this.$root.$emit("alert", "Password change error", 2)
            }
          }, true)
    }
  }
}
</script>
<template>
  <section class="relative w-full h-full py-40 min-h-screen">
    <div class="flex content-center items-center justify-center h-full">
      <div class="w-full lg:w-4/12 p-4">
        <div
          class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0"
        >
          <div class="flex-auto px-4 lg:px-10 py-10">
            <div class="relative w-full mb-3">
              <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
              >
                Email
              </label>
              <Input
                  type="email"
                  id="email"
                  :vkeyup="logInWithWeboa"
                  v-model="email"
                  placeholder="Email"
              />
            </div>

            <div class="relative w-full mb-3">
              <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
              >
                Password
              </label>
              <Input
                  type="password"
                  id="password"
                  :vkeyup="logInWithWeboa"
                  v-model="password"
                  placeholder="Password"
              />
            </div>

            <div class="text-center mt-6">
              <Button
                  @wasClicked="logInWithWeboa()"
              >
                Sign In
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Input from "@/components/Inter/Input";
import Button from "@/components/Inter/Button";


export default {
  name: 'Login',
  components: {
    Input, Button,
  },
  data() {
    return {
      email: "",
      password: "",
    }
  },
  methods: {
    async logInWithWeboa()  {
      if(this.email && this.password) {
        await this.$store.dispatch("getJWT", {
          email: this.email,
          password: this.password,
          that: this,
        })
      }
    },
  }
}
</script>
<template>
  <div :class="margin+' '+flex">
    <div class="lds-roller" :style="loader" :class="loaderC">
      <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loader",
  props: {
    margin: {
      type: String,
      default: "my-8"
    },
    flex: {
      type: String,
      default: "flex justify-center items-center"
    },
    loader: {
      type: String,
      default: ""
    },
    loaderC: {
      type: String,
      default: ""
    }
  }
}
</script>